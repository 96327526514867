import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/common/Layout";
import { Home } from "./pages/Home";
import { BlogSinglePage } from "./components/common/BlogSinglePage";
import { About } from "./pages/About";
import { Internships } from "./pages/Internships";
import { Blog } from "./pages/Blog";
import { Instructor } from "./pages/Instructor";
import Partnership from "./pages/partnership/Partnership";
import Verification from "./pages/Verification";
import InternshipDetails from "./pages/InternshipDetail/InternshipDetail";
import { Contact } from "./pages/Contact";
 import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/about"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="/contact"
            element={
              <Layout>
                <Contact />
              </Layout>
            }
          />
          <Route path="/verification" element={<Verification />} />
        
          <Route
            path="/internships"
            element={
              <Layout>
                <Internships />
              </Layout>
            }
          />
          <Route
            path="/instructor"
            element={
              <Layout>
                <Instructor />
              </Layout>
            }
          />
          <Route
            path="/partnership"
            element={
              <Layout>
                {" "}
                <Partnership />
              </Layout>
            }
          />
          <Route
            path="/internshipdetail"
            element={
              <Layout>
                {" "}
                <InternshipDetails />
              </Layout>
            }
          />
          <Route
            path="/blog"
            element={
              <Layout>
                <Blog />
              </Layout>
            }
          />
          <Route
            path="/single-blog"
            element={
              <Layout>
                <BlogSinglePage />
              </Layout>
            }
          />
        </Routes>

      </BrowserRouter>
      <Analytics/>
      
    </>
  );
}

export default App;
