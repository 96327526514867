export const LinkData = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "About",
    url: "/about",
  },
  {
    id: 3,
    title: "Internships",
    url: "/internships",
  },
 
  {
    id: 5,
    title: "Contact",
    url: "/contact",
  },
]
export const internships = [
  {
    id: 1,
    cover: "../images/web dev.jpg",
    title: "Web Development",
    description:"Gain hands-on experience building dynamic websites using HTML, CSS, JavaScript, and frameworks like React. Collaborate with experienced developers, enhance your coding skills, and contribute to real-world projects."
    
  },
  {
    id: 2,
    cover: "../images/app.jpg",
    title: "App Development",
    description:"Work on developing mobile applications using Flutter, Swift, or Kotlin. Join a creative team, improve your app-building skills, and participate in the full development lifecycle from concept to deployment."
    
  },
  {
    id: 3,
    cover: "../images/java.jpg",
    title: "Java Development",
    description:" Enhance your Java programming skills by working on backend systems, APIs, and enterprise solutions. Collaborate with seasoned professionals and contribute to impactful projects."
    
  },
  {
    id: 4,
    cover: "../images/marketing.jpg",
    title: "Marketing",
    description:"Develop your marketing skills by working on campaigns, social media management, content creation, and market research. Gain practical experience and contribute to enhancing brand presence."
    
  },
  {
    id: 5,
    cover: "../images/design.jpg",
    title: "Graphic Design",
    description:" Express your creativity by designing visual content for digital and print media. Use tools like Adobe Photoshop, Illustrator, and InDesign to create compelling graphics and marketing materials."
    
  },
  {
    id: 6,
    cover: "../images/data science.jpg",
    title: "Data Science",
    description:"Analyze data and generate insights using Python, R, and SQL. Work on real-world projects, develop machine learning models, and contribute to data-driven decision-making processes."
  },
]
