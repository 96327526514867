
const ContactHeader = () => {
  return (
      <div className="heading p-10 text-center w-2/3 m-auto md:w-full">
        <h1 className="text-3xl font-bold text-black">GET IN TOUCH</h1>
        <span className="text-[14px] mt-2 block py-5 font-semibold">
             WE'RE HERE TO HELP, AND WE'D LOVE TO HEAR FROM YOU! WHETHER YOU HAVE A QUESTION, COMMENT, OR JUST WANT TO CHAT, YOU CAN REACH OUT TO US THROUGH THE CONTACT FROM OF THIS PAGE, OR BY PHONE, EMAIL, OR SOCIAL MEDIA.
        </span>
      </div>
  )
};

export default ContactHeader;